<template>
    <!-- Banner Cookies -->
    <div
        id="cookie-banner-lgpd"
        class="cookie-banner-lgpd-visible cookie-banner-lgpd-animated"
        style="display: flex"
        v-if="cookies"
    >
        <div class="cookie-banner-lgpd-container">
        <div class="cookie-banner-lgpd_text-box">
            <span class="cookie-banner-lgpd_text">
                Nós usamos cookies e outras tecnologias semelhantes para melhorar a
                sua experiência em nossos serviços e recomendar conteúdo de seu interesse.
                Ao utilizar nossos serviços, você concorda com tal monitoramento. Acesse
                <router-link class="cookie-banner-lgpd-link cookie-banner-lgpd_privacy-link"
                target="_blank"
                style="color: #0069de" :to="{name: 'politicadeprivacidade'}">Política de Privacidade</router-link>
                <span> e veja a nossa nova Política.</span>
            </span>
        </div>
        <div class="cookie-banner-lgpd_button-box">
            <button
            class="cookie-banner-lgpd_accept-button"
            style="background-color: #0069de"
            v-on:click="acceptCookies()">
            ENTENDI
            </button>
        </div>
        </div>
    </div>
    <!-- End Banner Cookies -->
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            cookies: state => state.cookies
        })
    },
    mutations: {
        SET_COOKIES (state, cookies){
            state.items = cookies
        }
    },
    methods: {
        ...mapMutations({
            setCookies: 'SET_COOKIES'
        }),
        acceptCookies () {
            this.setCookies(false)
            localStorage.acceptCookies = false;
        }
    }
}
</script>